import { createContext, useEffect, useState } from 'react';
import { useAuth } from './useAuth';
import posthog from 'posthog-js';
import { useRouter } from 'next/router';

const PostHogContext = createContext(null);

export const PostHogProvider = ({ children }) => {
  const { user } = useAuth();
  const router = useRouter();
  const [inited, setInited] = useState(false);
  const [identified, setIdentified] = useState(false);

  useEffect(() => {
    if (inited) return;

    // Init PostHog
    posthog.init('phc_buIkr9p1LIx0f8N5gf9IZnNuH3gXmdBMDWfiEO2gUz5', {
      api_host: 'https://app.posthog.com',
      loaded: (posthog) => {
        if (process.env.NODE_ENV === 'development') {
          posthog.opt_out_capturing();
        }
      },
    });

    // Track page views
    const handleRouteChange = () => posthog.capture('$pageview');
    router.events.on('routeChangeComplete', handleRouteChange);
    setInited(true);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [inited, router]);

  useEffect(() => {
    if (user?.uid && !identified) {
      posthog.identify(user.uid, {
        email: user.email,
        name: user.name,
      });
      setIdentified(true);
    }
  }, [user, identified]);

  return (
    <PostHogContext.Provider value={{ posthog }}>
      {children}
    </PostHogContext.Provider>
  );
};
