import { AppProps } from 'next/app';

import { AuthProvider } from 'hooks/useAuth';
import { TeamProvider } from 'hooks/useTeam';
import { ToastProvider } from 'hooks/useToast';
import PlausibleProvider from 'next-plausible';
import 'css/tailwind.css';
import '@tremor/react/dist/esm/tremor.css';
import { PostHogProvider } from 'hooks/usePostHog';

export default function App({ Component, pageProps }: AppProps): JSX.Element {
  return (
    <AuthProvider>
      <PostHogProvider>
        <TeamProvider>
          <ToastProvider>
            <PlausibleProvider domain="latencylingo.com">
              <Component {...pageProps} />
            </PlausibleProvider>
          </ToastProvider>
        </TeamProvider>
      </PostHogProvider>
    </AuthProvider>
  );
}
